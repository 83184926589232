import React from "react"
import { Card } from "semantic-ui-react"

const NotFoundPage = () => (
  <Card>
    <h2>404: Not Found</h2>
  </Card>
)

export default NotFoundPage
